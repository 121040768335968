import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_14 from '../../images/leistungen/img14.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Hyaluronsäure Filler | Expertin in Bochum" description="Dr. K. Kostka bietet professionelle Filler - Behandlungen mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Hyaluronsäure"} subtitle={"Filler - Behandlung mitten in Bochum"} frontImage={img_leistung_14}></HeadlineWithImage>
          </Col>
        </Row>
        Mit dem Alter verliert die Gesichtshaut Kollagen, Fett, Elastin und Hyaluron. Es entsteht ein Volumenverlust und ein Nachlassen der Spannkraft.
        <br/>
        <br/>
        Hyaluronsäure ist ein wasserbindender und körpereigener Bestandteil der Haut. Durch die Unterspritzung mit Hyaluronsäure entsteht ein Hebeeffekt der Falten und Gesichtsareale. So können Konturen wiederhergestellt und eine Straffung des Gesichts erzielt werden. Der Behandlungseffekt ist sofort sichtbar und minimal-invasiv.
        <br/>
        <br/>
        Entscheidend ist immer ein natürliches Ergebnis. In einem eingehenden Beratungsgespräch gehe ich sehr gerne auf ihre Vorstellungen und Wünsche ein. Vereinbaren Sie gerne telefonisch oder per Mail einen Termin in meiner Praxis in der Bochumer Innenstadt.
        <br/>
        <br/>
        <SimpleModernHeadline h3={true}>Übersicht zur Behandlung mit Hyaluronsäure:</SimpleModernHeadline>
        <ul>
          <li>Schmerz: gering, bei Bedarf kann eine Salbe mit einem Betäubungsmittel vorher aufgetragen werden</li>
          <li>Gesellschaftsfähig: in der Regel sofort (wobei es in den ersten Tagen zu einer leichten Schwellung kommen kann)</li>
          <li>Sichtbares Ergebnis: sofort</li>
          <li>Wirkdauer: je nach Filler 6-12 Monate </li>
        </ul>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
